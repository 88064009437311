@import "../../../global.scss";

.home {
  padding-top: 120px;

  @include mobile {
    padding-top: 75px;
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  position: relative;

  @include mobile {
    flex-direction: column;
    gap: 15px;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mobile {
    gap: 15px;
  }

  .title {
    color: #333;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;

    @include mobile {
      font-size: 24px;
      text-align: center;
      font-weight: 500;
    }

    span {
      color: #1FC04C;
      //font-weight: 700;
    }
  }
}

.text {
  color: #333;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include mobile {
    font-size: 16px;
    text-align: center;
    margin: 15px 0;

  }
}

.textMobile{
  color: #333;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 20px;
  //margin-bottom: 20px;
  display: flex;
  align-items: baseline;
  gap: 10px;

  div{
    flex: 1;
  }

  span{
    color: #1FC04C;
    //font-weight: 700;
  }

  svg{
    //flex: ;
  }

  @include mobile {
    font-size: 16px;

  }
}

.btn {
  box-shadow: 0px 15px 30px 0px #40B9004D;
  padding: 10px 24px;
  color: #333;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(59.96deg, #D9FF24 -5.17%, #BCFF2C 31.97%, #85E018 45.9%, #79D914 60.76%, #40B900 78.4%);
}

.homeImg {
  flex: 1;
  position: absolute;
  right: 0;

  @include mobile {
    width: 80%;
    position: relative;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-bottom: 100px;

  @include mobile{
    margin-bottom: 0;
  }
}

.textRow {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  row-gap: 5px;
  color: #333;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 60px;

  @include mobile {
    flex-direction: column;
    font-size: 14px;
    display: none;
  }

  svg{
    width: 15px;
    height: 15px;
  }

  span {
    //font-weight: 700;
    color: #1FC04C;
  }
}

.greenBgTitle {
  color: #000;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 50px;
  text-align: center;

  @include mobile {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;

  }

  span {
    color: #1FC04C;
    //font-weight: 700;
  }
}

.items {
  display: flex;
  align-items: center;
  gap: 140px;

  @include mobile {
    flex-direction: column;
    gap: 15px;
  }

  .item {
    width: 352px;
    height: 192px;
    padding-right: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 15px;
    background: linear-gradient(60deg, #D9FF24 -5.17%, #BCFF2C 31.97%, #85E018 45.9%, #79D914 60.76%, #40B900 78.4%);
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.17);

    color: #333;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include mobile {
      width: 200px;
      height: 129px;
      flex-direction: column;
      text-align: center;
      padding: 15px 2px;
      font-size: 16px;
    }

    svg {
      width: 100%;
      @include mobile {
        width: 100%;
        height: 100%;
      }
    }
  }
}
