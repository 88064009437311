@import "../../../global.scss";

.verification {
  padding-top: 80px;

  @include mobile{
    padding-top: 0;
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;

  @include mobile {
    flex-direction: column;
    gap: 15px;
    margin-top: 60px;
  }
}

.greenWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  position: relative;

  @include mobile {
    flex-direction: column-reverse;
    gap: 15px;
  }
}


.imgWrap {
  flex: 1;
  display: flex;
  justify-content: center;

  img {
    //flex: 1;

    @include mobile {
      width: 80%;
      //margin: 0 auto;
    }
  }
}

.imgText {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 100px;

  @include mobile {
    margin-top: 0;
    text-align: center;
  }
}

.textContent {
  flex: 1;
  position: relative;
  z-index: 2;
}

.title {
  color: #333;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-bottom: 50px;

  @include mobile {
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
  }

  span {
    color: #1FC04C;
    //font-weight: 700;
  }
}

.textItem {
  display: flex;
  gap: 15px;

  .textItemText {
    color: #333;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1;
    margin-bottom: 15px;

    @include mobile {
      font-size: 16px;
    }
  }

  svg {
    @include mobile {
      width: 16px;
      height: 16px;
    }
  }

  span {
    color: #1FC04C;
  }
}

.greenBgWrap {
  //margin-bottom: 70px;
  //padding-top: 70px;

  @include mobile {
    margin-bottom: 0;
    padding-top: 0;
  }
}

.greenBgTitle {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-wrap: nowrap;

  @include mobile {
    text-wrap: wrap;
    font-size: 20px;
    text-align: center;
  }

  span{
    color: #1FC04C;
    //font-weight: 700;
  }
}

.greenBgText {
  margin: 30px 0;
  color: #333;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @include mobile {
    font-size: 16px;
    text-align: center;
    margin: 15px 0;
  }

  span{
    //font-weight: 700;
    color: #1FC04C;
  }
}

.greenBgTextItem {
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;
  gap: 15px;

  @include mobile {
    gap: 10px;
    width: 80%;
    //align-items: center;
    margin: 0 auto 15px;
  }

  svg {
    @include mobile {
      width: 16px;
      height: 16px;

    }
  }

  .greenBgTextItemText {
    color: #333;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1;

    @include mobile {
      font-size: 16px;
    }
  }
}

.greenBgSubTitle {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;

  @include mobile{
   font-size: 14px;
    text-align: center;
  }
}

.securityImg {
  flex: 0.5;
  position: absolute;
  right: 0;
  z-index: 1;
  width: 40%;
  //width: 100%;

  @include mobile {
    width: 80%;
    position: relative;
  }
}

.itemsWrap {
  margin-top: 80px;

  @include mobile {
    margin-top: 60px;
  }
}

.textRow {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  color: #333;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include mobile {
    font-size: 14px;
  }

  svg {
    @include mobile {
      width: 6px;
      height: 6px;
    }
  }
}


.center {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @include mobile{
   flex-direction: column;
    gap: 30px;
  }

  .item {
    width: 352px;
    height: 192px;
    border-radius: 15px;
    background: linear-gradient(60deg, #D9FF24 -5.17%, #BCFF2C 31.97%, #85E018 45.9%, #79D914 60.76%, #40B900 78.4%);
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.17);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 15px 10px;

    @include mobile{
      width: 220px;
      height: 254px;
      padding: 16px;
      gap: 30px;
      justify-content: center;
    }

    svg{
      //width: 100%;
      //height: 100%;
    }

    .itemText {
      color: #333;
      text-align: center;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 22px */
      flex: 1;

      @include mobile{
       font-size: 16px;
        flex: 0;
      }

    }
  }
}
