@import "../../../global.scss";

.transfer {
  margin-top: 80px;

  @include mobile {
    margin-top: 60px;
  }
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 120px;

  @include mobile {
    gap: 15px;
    margin-bottom: 60px;
  }
}

.textRow {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #333;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @include mobile {
    flex-direction: column;
    font-size: 14px;
    display: none;
  }

  span {
    //font-weight: 700;
    color: #1FC04C;
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 27px;

  @include mobile {
    flex-direction: column;
    gap: 15px;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mobile {
    gap: 15px;
  }
}

.text {
  color: #333;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @include mobile {
    font-size: 14px;
    text-align: center;
  }

  span {
    //font-weight: 700;
    color: #1FC04C;
  }
}

.paymentImg {
  flex: 1;
  width: 40%;

  @include mobile {
    width: 80%;
  }
}

.greenBgTitle {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 30px;

  @include mobile {
    font-size: 22px;
    margin-bottom: 50px;
  }

  span {
    //font-weight: 700;
    color: #1FC04C;
  }
}

.rules {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .rule {
    display: flex;
    //align-items: center;
    gap: 15px;
    color: #333;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include mobile {
      font-size: 16px;
    }

    svg {
      @include mobile {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.greenBgWrap {
  //margin-bottom: 60px;
  //padding-top: 60px;
  //padding: 75px 0;

  @include mobile {
    margin-bottom: 0;
    padding: 0;
  }
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-top: 75px;

  @include mobile {
    margin-top: 30px;
    flex-direction: column;
    gap: 15px;
  }

  .item {
    width: 352px;
    height: 192px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 22px 25px 22px;
    border-radius: 15px;
    background: linear-gradient(60deg, #D9FF24 -5.17%, #BCFF2C 31.97%, #85E018 45.9%, #79D914 60.76%, #40B900 78.4%);
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.17);

    @include mobile {
      width: 225px;
      height: 139px;
      padding: 15px 0px;
    }

    svg {
      @include mobile {
        width: 100%;
        height: 100%;
      }
    }

    .itemText {
      flex: 1;
      color: #333;
      text-align: center;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 24px */

      @include mobile {
        font-size: 16px;

      }

      span {
        //font-weight: 700;
      }
    }
  }
}
