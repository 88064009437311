@import "../../../global.scss";

.personalArea {
  margin-top: 80px;

  @include mobile {
    margin-top: 60px;
  }
}

.wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;

  @include mobile {
    gap: 15px;
    flex-direction: column-reverse;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mobile {
    gap: 15px;
  }

}

.checks {
  margin-top: 20px;
  .check {
    display: flex;
    gap: 10px;
    align-items: baseline;

    color: #333;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;

    @include mobile {
      font-size: 16px;
      margin-bottom: 15px;
    }

    svg {
      @include mobile {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.laptopImg {
  flex: 1;

  position: absolute;
  right: 0;
  width: 35%;
  z-index: -1;
  top: 125px;

  @include mobile {
    width: 80%;
    position: static;
  }
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-top: 75px;

  @include mobile {
    flex-direction: column;
    margin-top: 15px;
    gap: 15px;
  }

  .item {
    width: 352px;
    height: 229px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 22px 25px 22px;
    border-radius: 15px;
    background: linear-gradient(60deg, #D9FF24 -5.17%, #BCFF2C 31.97%, #85E018 45.9%, #79D914 60.76%, #40B900 78.4%);
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.17);

    @include mobile {
      width: 200px;
      height: 179px;
      padding: 15px 2px;
    }

    svg {
      flex: 1;

      @include mobile {
        width: 100%;
        height: 100%;
        padding: 0 20px;

      }
    }

    .itemText {
      flex: 1;
      display: flex;
      align-items: center;
      color: #333;
      text-align: center;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include mobile{
        font-size: 16px ;
      }
    }
  }
}

.greenBgWrap {
  //margin-bottom: 40px;
  //padding: 55px 0;

  @include mobile{
    margin-bottom: 0;
    padding: 0;
  }
}

.greenBgTitle {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 60px;
  text-align: center;

  @include mobile {
    font-size: 20px;
    margin-bottom: 0;
  }

  span {
    color: #1FC04C;
    //font-weight: 700;
  }
}
