@import "../../../global.scss";

.crossBorderTransfers {
  margin-top: 80px;

  @include mobile {
    margin-top: 60px;
  }
}

.title {
  color: #333;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 120px;

  @include mobile {
    font-size: 14px;
    //margin-top: 16px;
    margin-bottom: 60px;
  }

  span {
    font-weight: 700;
  }
}

.wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 50px;

  @include mobile {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 15px;
  }
}

.mapImg {
  flex: 1;

  @include mobile {
    width: 100%;
    height: 100%;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 50px;

  @include mobile{
   gap: 15px;
  }
}

.textItem {

  @include mobile{
    margin-top: 30px;
  }
  .textItemTitle {
    color: #333;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;

    @include mobile {
      text-align: center;
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
}

.textItemItems {
  display: flex;
  align-items: center;
  column-gap: 50px;
  row-gap: 30px;
  flex-wrap: wrap;

  @include mobile{
   row-gap: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    //margin-top: 30px;
  }
}

.textItemItem {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 150px;
  color: #333;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @include mobile {
    font-size: 16px;
    width: 100px;
  }

  img {
    @include mobile {
      width: 24px;

    }
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-bottom: 7px;
  margin-top: 40px;

  @include mobile{
   flex-direction: column;
    margin-top: 15px;
  }
}

.text {
  color: #333;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include mobile{
   font-size: 16px;
    margin-top: 30px;
  }
}

.greenBgWrap {
  //padding-bottom: 70px;

  @include mobile{
    padding: 0;
  }
}

.greenBgTitle {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 60px;

  @include mobile{
   font-size: 20px;
    margin-bottom: 30px;
  }

  span {
    color: #1FC04C;
    //font-weight: 700;
  }
}

.allDeviceImg {
  flex: 1;
  display: flex;
  justify-content: center;

  @include mobile{
   width: 100%;
    margin-bottom: 20px;
  }

  img{
    @include mobile{
     width: 100%;
    }
  }
}

.textRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 5px;
  justify-content: center;
  margin-top: 30px;
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include mobile{
   font-size: 16px;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: baseline;
    //flex-direction: column;
  }



}

.center {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
