@import "../../global.scss";
.footer{
  //border-radius: 170px 170px 0 0;
  background: #F0F0F0;
  padding: 60px 0;
  margin-top: 80px;

  @include mobile{
   border-radius: 20px 20px 0 0;
    padding: 15px 0;
    margin-top: 60px;
  }
}

.wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile{
   flex-direction: column;
    gap: 30px;
  }

  div{
    //flex: 1
  }

}

.text{
  color: #333;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include mobile{
   text-align: center;
  }
}

.links{
  display: flex;
  flex-direction: column;
  gap: 15px;
  a{
    color: #1FC04C;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
}

.number{
  a{
    color: #333;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;

    @include mobile{
     font-size: 20px;
    }
  }
}

.mt60{
  margin-top: 60px;

  @include mobile{
    margin-top: 30px;
  }
}

.firstChild{
  width: 604px;

  @include mobile{
   width: 100%;
  }
}
