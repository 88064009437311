@import "../../global.scss";
.header {
  padding: 15px 0;

  @include mobile{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 5;
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo{
  @include mobile{
    width: 143px;
    object-fit: contain;
  }
}

.number{
  @include mobile{
    display: none;
  }
  a {
    color: #333;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }
}

.actionBtn{
  padding: 10px 24px;
  box-shadow: 0px 15px 30px 0px #40B9004D;
  border-radius: 10px;

  color: #1FC04C;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
  cursor: pointer;

  @include mobile{
    padding: 8px;
  }
}
