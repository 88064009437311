@import "../../global.scss";

.modal {
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentWrap {
  max-width: 80%;
  margin: 0 auto;
}

.modalContent {
  width: 550px;
  height: max-content;
  padding: 30px 0;
  position: relative;
  background: #fff;
  border-radius: 15px;

  @include mobile {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .inputWrap {
    margin-bottom: 30px;
  }

  input, textarea {
    border-radius: 10px;
    background: #FFF;
    border: none;
    outline: none;
    padding: 15px 50px;
    box-shadow: 4px 4px 15px 0px #00000040 !important;
    -webkit-box-shadow: 4px 4px 15px 0px #00000040 !important;
    -moz-box-shadow: 4px 4px 15px 0px #00000040 !important;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    //text-transform: uppercase;
    -webkit-appearance: none;

    width: 100%;
    resize: none;
  }

  .error{
    color: red;
    margin-top: 5px;
  }

  textarea {
    height: 150px;
  }
}

.closeIcon {
  top: 45px;
  position: absolute;
  right: 45px;
  cursor: pointer;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  img {
    //width: 100%;
  }
}

.center {
  display: flex;
  justify-content: center;
}
