@import "../../global.scss";
.bg{
  padding: 65px 0;
  background: url("../../assets/mainBg.png") repeat;
  //background-position: left;
  background-size: 100%;

  @include mobile{
    background: #D2F2DB;
    //border-radius: 50px 50px 0 0;
    margin-top: 60px !important;
    padding: 30px 0;
  }
}
