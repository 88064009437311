@import "./global.scss";
.title{
  color: #000;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;

  @include mobile{
    font-size: 22px;
    text-align: center;
  }

  span{
    color: #1FC04C;
    //font-weight: 700;
  }
}

.btn{
  box-shadow: 0px 15px 30px 0px #40B9004D;
  padding: 10px 24px;
  color: #333;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(59.96deg, #D9FF24 -5.17%, #BCFF2C 31.97%, #85E018 45.9%, #79D914 60.76%, #40B900 78.4%);

  @include mobile{
   padding: 10px 50px;
  }
}

.mobile{
  display: none !important;

  @include mobile{
    display: block !important;
  }
}

.noneMobile{
  display: block !important;

  @include mobile{
    display: none !important;
  }
}

.mobileFlex{
  display: none !important;

  @include mobile{
    display: flex !important;
  }
}

.noneMobileFlex{
  display: flex !important;

  @include mobile{
    display: none !important;
  }
}
