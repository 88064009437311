@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.container{
    max-width: 1140px;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .container{
        max-width: 90%;
        margin: 0 auto;
    }
}
